<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-overlay
          :show="loader"
          rounded="sm"
          variant="dark"
          :opacity="0.1"
        >
          <b-card title="Update Part">
            <validation-observer ref="updatePartValidator">
              <b-form @submit.prevent="onSubmit">
                <b-row>
                  <b-col cols="12">
                    <b-form-group
                      label="Name"
                      label-for="name"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="name"
                        rules="required"
                      >
                        <b-form-input
                          id="name"
                          v-model="form.name"
                          name="name"
                          placeholder="Enter part name"
                        />
                        <span class="text-danger error-msg">{{ errors[0] }}</span>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group
                      label="Description"
                      label-for="description"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="description"
                        rules="required"
                      >
                        <b-textarea
                          id="description"
                          v-model="form.description"
                          name="description"
                          placeholder=""
                        />
                        <span class="text-danger error-msg">{{ errors[0] }}</span>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <div class="d-flex justify-content-end">
                      <b-button
                        variant="primary"
                        type="submit"
                      >
                        Update
                      </b-button>
                    </div>
                  </b-col>
                </b-row>
              </b-form>
            </validation-observer>
          </b-card>
        </b-overlay>
      </b-col>
    </b-row>
  </div>
</template>
<script>

import { createNamespacedHelpers } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const smartHomeModule = createNamespacedHelpers('smartHome')

export default {
  data() {
    return {
      loader: false,
      form: {
        name: '',
        description: '',
      },
      currentData: null,
    }
  },
  async mounted() {
    this.loader = true
    await this.fetchPart()
    this.loader = false
  },
  methods: {
    ...smartHomeModule.mapActions(['FETCH_SINGLE_PART', 'UPDATE_PART']),

    async fetchPart() {
      try {
        this.loader = true
        const resp = await this.FETCH_SINGLE_PART(this.$route.params.id)
        this.currentData = resp
        this.form.name = resp.name
        this.form.description = resp.description
        this.loader = false
      } catch (error) {
        this.loader = false
      }
    },

    onSubmit() {
      this.$refs.updatePartValidator.validate().then(async success => {
        if (success) {
          try {
            this.loader = true
            const newFrom = {
              name: this.form.name,
              description: this.form.description,
            }
            if (this.currentData.name === this.form.name) {
              delete newFrom.name
            }
            const resp = await this.UPDATE_PART({ id: this.$route.params.id, data: newFrom })
            if (resp) {
              this.currentData.name = this.form.name
              this.$nextTick(() => {
                this.$refs.updatePartValidator.reset()
              })
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Part Updated!',
                  icon: 'checkIcon',
                  variant: 'success',
                  text: 'The part has been updated successfully!',
                },
              })
            }
            this.loader = false
          } catch (error) {
            this.loader = false
          }
        }
      })
    },
  },
}
</script>
